import { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import styles from './SalesLine.module.css';

function SalesLineChart(props: any) {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  return (
    <>
      <div className={styles.header}>
        {props.name}
        {/* <span
          onClick={() => {
            setIsChecked(isChecked);
          }}
          style={{ cursor: 'pointer' }}
        > */}
        {/* {`${isChecked ? '' : 'w/o'} Managed Services `} //disabled. without Managed Services is hardcoded in API for now*/}
        {/* <label htmlFor='flexCheckDefault'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              checked={isChecked}
              id='flexCheckDefault'
              title='Exclude Managed Services'
              onChange={() => setIsChecked(!isChecked)}
            />
          </label> */}
        {/* </span> */}
      </div>
      <ResponsiveLine
        animate={true}
        curve='linear'
        data={props.data}
        enableSlices='x'
        enableTouchCrosshair
        yFormat='>-$,.0f'
        colors={{ scheme: 'set3' }}
        // isInteractive={false}
        lineWidth={3}
        pointSize={10}
        pointBorderWidth={1}
        pointBorderColor='black'
        // legends={[
        //   {
        //     anchor: 'bottom',
        //     direction: 'row',
        //     itemHeight: 20,
        //     itemWidth: 80,
        //     toggleSerie: true,
        //     translateY: 50
        //   }
        // ]}
        margin={{
          bottom: 60,
          left: 80,
          right: 20,
          top: 20
        }}
        // width={900}
        axisLeft={{
          legend: 'Sales',
          legendOffset: -76,
          legendPosition: 'middle',
          tickValues: 6,
          format: '>-$,.0f'
        }}
        axisBottom={
          {
            //   legend: 'Quarter',
            //   legendOffset: 30,
            //   legendPosition: 'middle'
          }
        }
        yScale={{
          max: 'auto',
          type: 'linear'
        }}
      />
    </>
  );
}

export default SalesLineChart;
