import React, { useEffect, useRef, useState, useContext } from 'react';
import logo from './SSLogo.webp';
import './App.css';
import { BrowserRouter, Route, Routes, redirect } from 'react-router-dom';
import { loginRequest } from './auth/authConfig';
import PrivateRoute from './auth/PrivateRoute';
import HomePage from './pages/project/Main/Projects';
import ContactsPage from './pages/Contact/Main/Contacts';
import ClientsPage from './pages/Client/Main/Clients';
import BillingPage from './pages/Billing/Main/Billing';
import Reporting from './pages/Reporting/Reporting';
import Employees from './pages/Employees/Employees';
import Dashboard from './pages/Dashboard/Dashboard';
import Unauthorized from './pages/Unauthorized/Unauthorized';
//import CreateProject from './pages/project/Main/CreateProject';
import Project from './pages/ProjectSample/ProjectSample';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useAccount,
  MsalAuthenticationTemplate
} from '@azure/msal-react';
import { callMsGraph } from './auth/graph';
import SignInOut from './pages/SignInOut/SignInOut';
import Sidebar from './components/common/Sidebar/Sidebar';
import BottomBar from './components/common/BottomBar/BottomBar';
import LogoBar from './components/common/logobar/LogoBar';
// import CreateContact from './pages/Contact/Main/CreateContact';
//import CreateClient from './pages/Client/Main/CreateClient';
import 'bootstrap/dist/css/bootstrap.min.css';
import HelpUs from './pages/Helpus/helpus';
import { urlProjects, urlClients } from './endpoints';
import axios from 'axios';
import { accessTokenRequest } from './auth/authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import useTimeout from './useTimeout';
import { useGetClients, useClientList } from './context/ClientContext';
import { useUserList, useGetUserList } from './context/UserContext';
import { useContactList, useGetContacts } from './context/ContactContext';
import useSessionTimeout from './hooks/useSessionTimeout';
import { useAuthenticateDashboard } from './hooks/useAuthenticateDashboard';
import { useAuthenticateDatabase } from './hooks/useAuthenticateDatabase';
import { access } from 'fs';

function App() {
  const { instance, accounts, inProgress } = useMsal();
  const [accessTokenExists, setAccessTokenExists] = useState(false);
  const account = useAccount(accounts[0] || {});
  const [apiData, setApiData] = useState(null);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [FilterArray, SetFilterArray] = useState<{ key: string; value: string }[]>([]);
  const [checked, setChecked] = useState(true);
  const [sortWith, SetSortWith] = useState('');
  const [sortBy, SetSortBy] = useState('dec');
  const [search, setSearch] = useState('');
  const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
  const [projects, setProjects] = useState<any[]>([]);
  const [render, setRender] = useState(0);
  //const [clientList, setClientList] = useState<any[]>([]);
  const [reload, setReload] = useState(0);
  const [isActive, setIsActive] = useState(true);
  //const [employeeData, setEmployeeData] = useState<any[]>([]);
  const [authDashboard, setAuthDashboard] = useState(false);
  const [authDatabase, setAuthDatabase] = useState(true);
  const checkTokenDashboard = useAuthenticateDashboard();
  const checkTokenDatabase = useAuthenticateDatabase();

  let count = useRef(0);

  const name: string | undefined = accounts[0] && accounts[0].name;
  const userEmail: string | undefined = accounts[0] && accounts[0].username;
  const getClients: () => Promise<void> = useGetClients();
  const clientList = useClientList();
  const employeeData = useUserList();
  const getUsers: () => Promise<void> = useGetUserList();
  const contactList = useContactList();
  const getContacts: () => Promise<void> = useGetContacts();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    if (!apiData && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
        })
        .then((accessTokenResponse) => {
          let accessToken = accessTokenResponse.accessToken;
          sessionStorage.setItem('accessToken', accessToken);
          setAccessTokenExists(true);
          //console.log(accessToken);
          // debugger;
          // checkTokenDatabase();
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              account: accounts[0],
              scopes: [`${process.env.REACT_APP_API_PROTECTED_RESOURCE_API_SCOPES}`]
            });
          }
          console.log(error);
        });
    }
  }, [inProgress, accounts, instance, apiData]);

  useEffect(() => {
    if (accessTokenExists) {
      const isAuthenticatedDB = checkTokenDatabase();
      setAuthDatabase(isAuthenticatedDB);
    }
  }, [checkTokenDatabase, accessTokenExists]);

  useEffect(() => {
    const isAuthenticated = checkTokenDashboard();
    setAuthDashboard(isAuthenticated);
  }, [checkTokenDashboard]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  //console.log(windowSize.width);

  // useEffect(() => {
  //   if (!authDatabase) {
  //     getClients();
  //     getUsers();
  //     getContacts();
  //     checkTokenDashboard();
  //   }
  // }, [authDatabase, getClients, getUsers, getContacts, checkTokenDashboard]);

  useEffect(() => {
    const isAuthenticated = checkTokenDashboard();
    setAuthDashboard(isAuthenticated);
  }, [checkTokenDashboard]);

  function handleSessionTimeout() {
    console.log('session timeout');
  }

  const isSessionExpired = useSessionTimeout(10 * 60 * 1000, handleSessionTimeout);

  return sessionStorage.getItem('accessToken') !== null ||
    sessionStorage.getItem('accessToken') !== undefined ? (
    <>
      <UnauthenticatedTemplate>
        <SignInOut />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {!isSessionExpired ? (
          authDatabase ? (
            <Unauthorized />
          ) : (
            <BrowserRouter>
              <LogoBar name={name} useTimeOut={useTimeout} />
              {windowSize.width > 1180 || windowSize.height > 820 ? (
                <Sidebar auth={authDashboard} />
              ) : (
                <BottomBar />
              )}
              <div style={{ paddingLeft: '66px', paddingTop: '6%' }}>
                <Routes>
                  <Route path='/' element={<HomePage name={name} userEmail={userEmail} />} />
                  {/* <Route path='/home/CreateProject' element={<CreateProject />} /> */}
                  <Route path='/contacts' element={<ContactsPage />} />
                  {/* <Route path='/Employees' element={<Employees />} /> */}
                  <Route path='/clients' element={<ClientsPage />} />
                  <Route path='/billing' element={<BillingPage />} />
                  <Route path='/reporting' element={<Reporting />} />
                  {/* <Route path='/create_client' element={<CreateClient />} /> */}
                  <Route path='/projectSample' element={<Project />} />
                  {/* <Route path='/dashboard' element={<Dashboard />} /> */}
                  <Route element={<PrivateRoute auth={authDashboard} />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                  </Route>
                </Routes>
                {windowSize.width > 1180 || windowSize.height > 820 ? <HelpUs /> : null}
              </div>
            </BrowserRouter>
          )
        ) : (
          <SignInOut />
        )}
      </AuthenticatedTemplate>
    </>
  ) : null;
}

export default App;
